import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useState, useCallback } from "react";
import { IMultiComboQuestion } from "../IMultiComboQuestion";
import { Question } from "../Question";

interface IProps {
    readonly question: IMultiComboQuestion;
}

export const MultiComboQuestion: React.FC<React.PropsWithChildren<IProps>> = ({ question }) => {
    const { options } = question;
    const [listLength, setListLength] = useState(1);

    const onAddClicked = useCallback(() => {
        setListLength(listLength + 1);
    }, [listLength]);

    const onRemoveClicked = useCallback(() => {
        setListLength(listLength - 1);
    }, [listLength]);

    const optionList = Array.from({ length: listLength }).map((_, index) => (
        <div key={index} className="mb-4">
            {options.map((option) => (
                <Question key={`${option.jsonPath}-${index}`} question={option} />
            ))}
        </div>
    ));

    return (
        <>
            {optionList}
            <div className="bg-white px-4 py-5 sm:px-6">
                <div className="space-y-6">
                    <div className="flex flex-row-reverse items-center gap-x-1.5">
                        {listLength > 1 && (
                            <button
                                onClick={onRemoveClicked}
                                type="button"
                                className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <XMarkIcon aria-hidden="true" className="-ml-0.5 size-5" />
                                Remove
                            </button>
                        )}
                        <button
                            onClick={onAddClicked}
                            type="button"
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <PlusIcon aria-hidden="true" className="-ml-0.5 size-5" />
                            Add Another
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
