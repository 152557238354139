import { relationshipTypeLabels } from "@ds160/library";
import { FormQuestion } from "./formManifest";
import { getOptionsFromLabels } from "./getOptionsFromLabels";

export const travelCompanionsPage: ReadonlyArray<FormQuestion> = [
    {
        help: {
            title: "Traveling with Others",
            content:
                "You should answer Yes to this question if you are traveling with family, as part of an organized tour, or as part of a performing group or athletic team. You do not need to list individuals who are traveling with you for the purposes of employment with the same employer.",
        },
        title: "Are there other persons traveling with you?",
        type: "boolean",
        jsonPath: "hasTravelCompanions",
        subQuestions: {
            yes: {
                title: "Are you traveling as part of a group or organization?",
                jsonPath: "isTravelingAsGroup",
                type: "boolean",
                subQuestions: {
                    yes: {
                        type: "text",
                        title: "Enter the name of the group you are traveling with",
                        jsonPath: "travelingGroup",
                    },
                    no: {
                        type: "multi-combo",
                        title: "Provide the following information for each person traveling with you:",
                        jsonPath: "travelCompanions",
                        options: [
                            { type: "text", title: "Surnames of the person traveling with you", jsonPath: "surnames" },
                            { type: "text", title: "Given names of the person traveling with you", jsonPath: "givenNames" },
                            {
                                type: "dropdown",
                                title: "Relationship to you",
                                jsonPath: "relationship",
                                options: getOptionsFromLabels(relationshipTypeLabels),
                            },
                        ],
                    },
                },
            },
        },
    },
];
