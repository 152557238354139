import {
    LengthOfTimeUnit,
    lengthOfTimeUnitLabels,
    stateLabels,
    tripPayerLabels,
    visaCategoryALabels,
    visaCategoryBLabels,
    visaCategoryCLabels,
    visaCategoryCNMI,
    visaCategoryDLabels,
    visaCategoryELabels,
    visaCategoryFLabels,
    visaCategoryGLabels,
    visaCategoryHLabels,
    visaCategoryILabels,
    visaCategoryJLabels,
    visaCategoryKLabels,
    visaCategoryLLabels,
    visaCategoryMLabels,
    visaCategoryNATOLabels,
    visaCategoryNLabels,
    visaCategoryOLabels,
    visaCategoryPAROLEBENLabels,
    visaCategoryPLabels,
    visaCategoryQLabels,
    visaCategoryRLabels,
    visaCategorySLabels,
    visaCategoryTDTNLabels,
    visaCategoryTLabels,
    visaCategoryULabels,
    VisaType,
    visaTypeLabels,
} from "@ds160/library";
import { FormQuestion } from "./formManifest";
import { getOptionsFromLabels } from "./getOptionsFromLabels";

const visaTypes = getOptionsFromLabels(visaTypeLabels);
const typeAOptions = getOptionsFromLabels(visaCategoryALabels);
const typeBOptions = getOptionsFromLabels(visaCategoryBLabels);
const typeCOptions = getOptionsFromLabels(visaCategoryCLabels);
const typeCNMIOptions = getOptionsFromLabels(visaCategoryCNMI);
const typeDOptions = getOptionsFromLabels(visaCategoryDLabels);
const typeEOptions = getOptionsFromLabels(visaCategoryELabels);
const typeFOptions = getOptionsFromLabels(visaCategoryFLabels);
const typeGOptions = getOptionsFromLabels(visaCategoryGLabels);
const typeHOptions = getOptionsFromLabels(visaCategoryHLabels);
const typeIOptions = getOptionsFromLabels(visaCategoryILabels);
const typeJOptions = getOptionsFromLabels(visaCategoryJLabels);
const typeKOptions = getOptionsFromLabels(visaCategoryKLabels);
const typeLOptions = getOptionsFromLabels(visaCategoryLLabels);
const typeMOptions = getOptionsFromLabels(visaCategoryMLabels);
const typeNOptions = getOptionsFromLabels(visaCategoryNLabels);
const typeNATOOptions = getOptionsFromLabels(visaCategoryNATOLabels);
const typeOOptions = getOptionsFromLabels(visaCategoryOLabels);
const typePOptions = getOptionsFromLabels(visaCategoryPLabels);
const typeQOptions = getOptionsFromLabels(visaCategoryQLabels);
const typeROptions = getOptionsFromLabels(visaCategoryRLabels);
const typeSOptions = getOptionsFromLabels(visaCategorySLabels);
const typeTOptions = getOptionsFromLabels(visaCategoryTLabels);
const typeTDTNOptions = getOptionsFromLabels(visaCategoryTDTNLabels);
const typeUOptions = getOptionsFromLabels(visaCategoryULabels);
const typePAROLEBENOptions = getOptionsFromLabels(visaCategoryPAROLEBENLabels);

const specificVisaTypes: Record<VisaType, FormQuestion> = {
    [VisaType.FOREIGN_GOVERNMENT_OFFICIAL_A]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeAOptions,
    },
    [VisaType.TEMP_BUSINESS_OR_PLEASURE_VISITOR_B]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeBOptions,
    },
    [VisaType.ALIEN_IN_TRANSIT_C]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeCOptions,
    },
    [VisaType.CNMI_WORKER_OR_INVESTOR_CW_E2_C]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeCNMIOptions,
    },
    [VisaType.CREWMEMBER_D]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeDOptions,
    },
    [VisaType.TREATY_TRADER_OR_INVESTOR_E]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeEOptions,
    },
    [VisaType.ACADEMIC_OR_LANGUAGE_STUDENT_F]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeFOptions,
    },
    [VisaType.INTERNATIONAL_ORGANIZATION_REP_EMP_G]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeGOptions,
    },
    [VisaType.TEMPORARY_WORKER_H]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeHOptions,
    },
    [VisaType.FOREIGN_MEDIA_REPRESENTATIVE_I]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeIOptions,
    },
    [VisaType.EXCHANGE_VISITOR_J]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeJOptions,
    },
    [VisaType.FIANCÉ_E_OR_SPOUSE_OF_A_U_S_CITIZEN_K]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeKOptions,
    },
    [VisaType.INTRACOMPANY_TRANSFEREE_L]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeLOptions,
    },
    [VisaType.VOCATIONAL_NONACADEMIC_STUDENT_M]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeMOptions,
    },
    [VisaType.OTHER_N]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeNOptions,
    },
    [VisaType.NATO_STAFF_NATO]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeNATOOptions,
    },
    [VisaType.ALIEN_WITH_EXTRAORDINARY_ABILITY_O]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeOOptions,
    },
    [VisaType.INTERNATIONALLY_RECOGNIZED_ALIEN_P]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typePOptions,
    },
    [VisaType.CULTURAL_EXCHANGE_VISITOR_Q]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeQOptions,
    },
    [VisaType.RELIGIOUS_WORKER_R]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeROptions,
    },
    [VisaType.INFORMANT_OR_WITNESS_S]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeSOptions,
    },
    [VisaType.VICTIM_OF_TRAFFICKING_T]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeTOptions,
    },
    [VisaType.NAFTA_PROFESSIONAL_TD_TN]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeTDTNOptions,
    },
    [VisaType.VICTIM_OF_CRIMINAL_ACTIVITY_U]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typeUOptions,
    },
    [VisaType.PAROLE_BENEFICIARY_PARCIS]: {
        jsonPath: "visaType",
        type: "dropdown",
        title: "Specify Visa",
        options: typePAROLEBENOptions,
    },
};

export const visaPurposePage: ReadonlyArray<FormQuestion> = [
    {
        jsonPath: "purposes",
        type: "multi-combo",
        title: "Purpose of Visit",
        options: [
            {
                title: "Purpose of Trip to the U.S.",
                jsonPath: "category",
                type: "dropdown",
                options: visaTypes,
                subquestions: specificVisaTypes,
            },
        ],
    },
    {
        title: "Have you made specific travel plans?",
        type: "boolean",
        jsonPath: "specificTravelPlans",
        subQuestions: {
            yes: {
                jsonPath: "specificTravelPlans",
                type: "combo",
                title: "Provide a complete itinerary for your travel to the U.S.",
                options: [
                    {
                        help: {
                            title: "Travel Plans",
                            content:
                                "If you are unsure of your Date of Arrival in U.S. or Date of Departure from U.S., please provide an estimate.",
                        },
                        type: "date",
                        title: "Arrival Date",
                        jsonPath: "arrivalDate",
                    },
                    { title: "Arrival Flight (if known)", jsonPath: "arrivalFlight", type: "text" },
                    { title: "Arrival City", jsonPath: "arrivalCity", type: "text" },
                    {
                        type: "date",
                        title: "Departure Date",
                        jsonPath: "departureDate",
                    },
                    { title: "Departure Flight (if known)", jsonPath: "departureFlight", type: "text" },
                    { title: "Departure City", jsonPath: "departureCity", type: "text" },
                    {
                        title: "Locations you plan to visit:",
                        jsonPath: "locations",
                        type: "multi-combo",
                        options: [
                            {
                                title: "Location",
                                jsonPath: "location",
                                type: "text",
                            },
                        ],
                    },
                ],
            },
            no: {
                help: {
                    title: "Arrival Plans",
                    content: "If you are unsure of your travel plans, please provide an estimate.",
                },
                jsonPath: "specificTravelPlans",
                type: "combo",
                title: "Please provide an explanation of your travel plans.",
                options: [
                    {
                        type: "date",
                        title: "Arrival Date",
                        jsonPath: "arrivalDate",
                    },
                    { title: "Intended length of stay in the U.S.", jsonPath: "lengthOfStayNumber", type: "text" },
                    {
                        title: "",
                        jsonPath: "lengthOfStayNumberUnits",
                        type: "dropdown",
                        options: getOptionsFromLabels(lengthOfTimeUnitLabels).filter((option) => {
                            return option.value !== LengthOfTimeUnit.LESS_THAN_24HOURS;
                        }),
                    },
                ],
            },
        },
    },
    {
        title: "Address where you will stay in the U.S.",
        type: "combo",
        jsonPath: "address",
        options: [
            {
                title: "Address Line 1",
                jsonPath: "address",
                type: "text",
            },
            {
                title: "Address Line 2",
                jsonPath: "address2",
                type: "text",
            },
            {
                title: "City",
                jsonPath: "city",
                type: "text",
            },
            {
                title: "State",
                jsonPath: "state",
                type: "dropdown",
                options: getOptionsFromLabels(stateLabels),
            },
            {
                title: "Zip Code",
                jsonPath: "zip",
                type: "text",
            },
            {
                title: "Person/Entity Paying for Your Trip",
                jsonPath: "payingForTrip",
                type: "dropdown",
                options: getOptionsFromLabels(tripPayerLabels),
            },
        ],
    },
];
