import { ConsulateCode } from "@ds160/library";
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useCallback, useState } from "react";
import { IDropdownQuestion } from "../IDropdownQuestion";
import { Question } from "../Question";

interface IProps {
    readonly question: IDropdownQuestion;
}
export const DropdownQuestion: React.FC<IProps> = ({ question }) => {
    const { title, help, subquestions } = question;
    const [selected, setSelected] = useState<string | null>(null);
    const onSelectionChanged = useCallback((value: ConsulateCode) => {
        setSelected(value);
    }, []);

    const options = question.options;
    const selectedLabel = options.find((option) => option.value === selected)?.label ?? "Select an option";
    const selectedSubquestion = subquestions?.[selected ?? ""];
    return (
        <>
            <div className="bg-white px-4 py-5 sm:px-6">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base/7 font-semibold text-gray-900">{help?.title}</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">{help?.content}</p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-4">
                            <div>
                                <Listbox value={selected} onChange={onSelectionChanged}>
                                    <Label className="block text-sm/6 font-medium text-gray-900">{title}</Label>
                                    <div className="relative mt-2">
                                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm/6">
                                            <span className="block truncate">{selectedLabel}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon aria-hidden="true" className="size-5 text-gray-400" />
                                            </span>
                                        </ListboxButton>

                                        <ListboxOptions
                                            transition
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                        >
                                            {options.map((option) => {
                                                const { value, label } = option;
                                                return (
                                                    <ListboxOption
                                                        key={value}
                                                        value={value}
                                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                                    >
                                                        <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                                            {label}
                                                        </span>

                                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                            <CheckIcon aria-hidden="true" className="size-5" />
                                                        </span>
                                                    </ListboxOption>
                                                );
                                            })}
                                        </ListboxOptions>
                                    </div>
                                </Listbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selectedSubquestion && <Question question={selectedSubquestion} />}
        </>
    );
};
