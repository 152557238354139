import * as React from "react";
import { FormQuestion } from "./manifest/formManifest";
import { BooleanQuestion } from "./Question/BooleanQuestion";
import { ComboQuestion } from "./Question/ComboQuestion";
import { DateQuestion } from "./Question/DateQuestion";
import { DropdownQuestion } from "./Question/DropdownQuestion";
import { MultiComboQuestion } from "./Question/MultiComboQuestion";
import { MultiTextQuestion } from "./Question/MultiTextQuestion";
import { MultiTextListQuestion } from "./Question/MultiTextQuestionList";
import { TextQuestion } from "./Question/TextQuestion";

interface IProps {
    readonly question: FormQuestion;
}

export const Question: React.FC<React.PropsWithChildren<IProps>> = ({ question }) => {
    if (question.type === "text") {
        return <TextQuestion question={question} />;
    }
    if (question.type === "boolean") {
        return <BooleanQuestion question={question} />;
    }
    if (question.type === "multi-text") {
        return <MultiTextQuestion question={question} />;
    }
    if (question.type === "multi-text-list") {
        return <MultiTextListQuestion question={question} />;
    }
    if (question.type === "dropdown") {
        return <DropdownQuestion question={question} />;
    }

    if (question.type === "date") {
        return <DateQuestion question={question} />;
    }

    if (question.type === "multi-combo") {
        return <MultiComboQuestion question={question} />;
    }

    if (question.type === "combo") {
        return <ComboQuestion question={question} />;
    }

    return (
        <>
            Unknown question type: <pre>{JSON.stringify(question, null, 4)}</pre>
        </>
    );
};
