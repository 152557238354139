import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useCallback, useState } from "react";
import { IMultiTextListQuestion } from "../IMultiTextListQuestion";

interface IProps {
    readonly question: IMultiTextListQuestion;
}

export const MultiTextListQuestion: React.FC<React.PropsWithChildren<IProps>> = ({ question }) => {
    const { title, hint, help, options } = question;
    const [listLength, setListLength] = useState(1);

    const onAddClicked = useCallback(() => {
        setListLength(listLength + 1);
    }, [listLength]);

    const onRemoveClicked = useCallback(() => {
        setListLength(listLength - 1);
    }, [listLength]);

    const optionList = Array.from({ length: listLength }).map((_, index) => {
        const list = options.map((option) => {
            return (
                <div key={option.jsonPath} className="sm:col-span-4">
                    <div>
                        <label htmlFor={option.jsonPath} className="mt-1 text-sm/6 text-gray-600">
                            {option.title}
                        </label>
                        <div className="mt-2">
                            <input
                                id={option.jsonPath}
                                name={option.jsonPath}
                                type="text"
                                placeholder={hint}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                        </div>
                    </div>
                </div>
            );
        });
        return <div key={index}>{list}</div>;
    });

    return (
        <>
            <div className="bg-white px-4 py-5 sm:px-6">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base/7 font-semibold text-gray-900">{help?.title}</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">{help?.content}</p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        {
                            <div className="sm:col-span-4">
                                <div>
                                    <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                        {title}
                                    </label>
                                    <div className="my-2  space-y-8">{optionList}</div>
                                    <div className="flex flex-row-reverse items-center gap-x-1.5">
                                        {listLength > 1 && (
                                            <button
                                                onClick={onRemoveClicked}
                                                type="button"
                                                className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                <XMarkIcon aria-hidden="true" className="-ml-0.5 size-5" />
                                                Remove
                                            </button>
                                        )}
                                        <button
                                            onClick={onAddClicked}
                                            type="button"
                                            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            <PlusIcon aria-hidden="true" className="-ml-0.5 size-5" />
                                            Add Another
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
