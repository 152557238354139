import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { IBooleanQuestion } from "../IBooleanQuestion";
import { Question } from "../Question";

interface IProps {
    readonly question: IBooleanQuestion;
}

export const BooleanQuestion: React.FC<React.PropsWithChildren<IProps>> = ({ question }) => {
    const { jsonPath, title, hint, help, subQuestions } = question;
    const [value, setValue] = useState<boolean | null>(null);

    const onYesSelected = useCallback(() => setValue(true), []);
    const onNoSelected = useCallback(() => setValue(false), []);

    const subQuestion = useMemo(() => {
        if (value === null) {
            return null;
        }
        if (value === true) {
            return subQuestions?.yes ?? null;
        }
        return subQuestions?.no ?? null;
    }, [value, subQuestions]);

    const nestedQuestion = subQuestion !== null ? <Question question={subQuestion} /> : null;
    return (
        <>
            <div className="bg-white px-4 py-5 sm:px-6">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base/7 font-semibold text-gray-900">{help?.title}</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">{help?.content}</p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-4">
                            <div>
                                <fieldset>
                                    <legend className="text-sm/6 font-semibold text-gray-900">{title}</legend>
                                    <p className="mt-1 text-sm/6 text-gray-600">{hint}</p>
                                    <div className="mt-6 space-y-6">
                                        <div className="flex items-center gap-x-3">
                                            <input
                                                id={`${jsonPath}-yes`}
                                                name={jsonPath}
                                                type="radio"
                                                onChange={onYesSelected}
                                                className="size-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                            <label htmlFor="push-everything" className="block text-sm/6 font-medium text-gray-900">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-x-3">
                                            <input
                                                id={`${jsonPath}-no`}
                                                name={jsonPath}
                                                onChange={onNoSelected}
                                                type="radio"
                                                className="size-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                            <label htmlFor="push-email" className="block text-sm/6 font-medium text-gray-900">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`transform transition-all duration-300 ${
                    nestedQuestion ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-2 pointer-events-none"
                }`}
            >
                {nestedQuestion}
            </div>
        </>
    );
};
