import * as React from "react";

import { IComboQuestion } from "../IComboQuestion";
import { Question } from "../Question";

interface IProps {
    readonly question: IComboQuestion;
}

export const ComboQuestion: React.FC<React.PropsWithChildren<IProps>> = ({ question }) => {
    const { options, hint, title, help } = question;

    const optionList = options.map((option) => {
        return <Question key={option.jsonPath} question={option} />;
    });
    const [firstOption, ...restOptions] = optionList;
    return (
        <>
            <div className="bg-white px-4 py-5 sm:px-6">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base/7 font-semibold text-gray-900">{help?.title}</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">{help?.content}</p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-4">
                            <div>
                                <fieldset>
                                    <legend className="text-sm/6 font-semibold text-gray-900">{title}</legend>
                                    <p className="mt-1 text-sm/6 text-gray-600">{hint}</p>
                                </fieldset>
                            </div>
                            {firstOption}
                        </div>
                    </div>
                </div>
            </div>
            {restOptions}
        </>
    );
};
