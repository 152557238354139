export const birthCountryList: ReadonlyArray<{
    readonly value: string;
    readonly label: string;
}> = [
    { value: "AFGH", label: "AFGHANISTAN" },
    { value: "ALB", label: "ALBANIA" },
    { value: "ALGR", label: "ALGERIA" },
    { value: "ASMO", label: "AMERICAN SAMOA" },
    { value: "ANDO", label: "ANDORRA" },
    { value: "ANGL", label: "ANGOLA" },
    { value: "ANGU", label: "ANGUILLA" },
    { value: "ANTI", label: "ANTIGUA AND BARBUDA" },
    { value: "ARG", label: "ARGENTINA" },
    { value: "ARM", label: "ARMENIA" },
    { value: "ARB", label: "ARUBA" },
    { value: "XAS", label: "AT SEA" },
    { value: "ASTL", label: "AUSTRALIA" },
    { value: "AUST", label: "AUSTRIA" },
    { value: "AZR", label: "AZERBAIJAN" },
    { value: "BAMA", label: "BAHAMAS" },
    { value: "BAHR", label: "BAHRAIN" },
    { value: "BANG", label: "BANGLADESH" },
    { value: "BRDO", label: "BARBADOS" },
    { value: "BYS", label: "BELARUS" },
    { value: "BELG", label: "BELGIUM" },
    { value: "BLZ", label: "BELIZE" },
    { value: "BENN", label: "BENIN" },
    { value: "BERM", label: "BERMUDA" },
    { value: "BHU", label: "BHUTAN" },
    { value: "BOL", label: "BOLIVIA" },
    { value: "BIH", label: "BOSNIA-HERZEGOVINA" },
    { value: "BOT", label: "BOTSWANA" },
    { value: "BRZL", label: "BRAZIL" },
    { value: "IOT", label: "BRITISH INDIAN OCEAN TERRITORY" },
    { value: "BRNI", label: "BRUNEI" },
    { value: "BULG", label: "BULGARIA" },
    { value: "BURK", label: "BURKINA FASO" },
    { value: "BURM", label: "BURMA" },
    { value: "BRND", label: "BURUNDI" },
    { value: "CBDA", label: "CAMBODIA" },
    { value: "CMRN", label: "CAMEROON" },
    { value: "CAN", label: "CANADA" },
    { value: "CAVI", label: "CABO VERDE" },
    { value: "CAYI", label: "CAYMAN ISLANDS" },
    { value: "CAFR", label: "CENTRAL AFRICAN REPUBLIC" },
    { value: "CHAD", label: "CHAD" },
    { value: "CHIL", label: "CHILE" },
    { value: "CHIN", label: "CHINA" },
    { value: "CHRI", label: "CHRISTMAS ISLAND" },
    { value: "COCI", label: "COCOS KEELING ISLANDS" },
    { value: "COL", label: "COLOMBIA" },
    { value: "COMO", label: "COMOROS" },
    { value: "COD", label: "CONGO, DEMOCRATIC REPUBLIC OF THE" },
    { value: "CONB", label: "CONGO, REPUBLIC OF THE" },
    { value: "CKIS", label: "COOK ISLANDS" },
    { value: "CSTR", label: "COSTA RICA" },
    { value: "IVCO", label: "COTE D`IVOIRE" },
    { value: "HRV", label: "CROATIA" },
    { value: "CUBA", label: "CUBA" },
    { value: "CUR", label: "CURACAO" },
    { value: "CYPR", label: "CYPRUS" },
    { value: "CZEC", label: "CZECH REPUBLIC" },
    { value: "DEN", label: "DENMARK" },
    { value: "DJI", label: "DJIBOUTI" },
    { value: "DOMN", label: "DOMINICA" },
    { value: "DOMR", label: "DOMINICAN REPUBLIC" },
    { value: "ECUA", label: "ECUADOR" },
    { value: "EGYP", label: "EGYPT" },
    { value: "ELSL", label: "EL SALVADOR" },
    { value: "EGN", label: "EQUATORIAL GUINEA" },
    { value: "ERI", label: "ERITREA" },
    { value: "EST", label: "ESTONIA" },
    { value: "SZLD", label: "ESWATINI" },
    { value: "ETH", label: "ETHIOPIA" },
    { value: "FKLI", label: "FALKLAND ISLANDS" },
    { value: "FRO", label: "FAROE ISLANDS" },
    { value: "FIJI", label: "FIJI" },
    { value: "FIN", label: "FINLAND" },
    { value: "FRAN", label: "FRANCE" },
    { value: "FPOL", label: "FRENCH POLYNESIA" },
    { value: "FSAT", label: "FRENCH SOUTHERN AND ANTARCTIC TERRITORIES" },
    { value: "GABN", label: "GABON" },
    { value: "GAM", label: "GAMBIA, THE" },
    { value: "XGZ", label: "GAZA STRIP" },
    { value: "GEO", label: "GEORGIA" },
    { value: "GER", label: "GERMANY" },
    { value: "GHAN", label: "GHANA" },
    { value: "GIB", label: "GIBRALTAR" },
    { value: "GRC", label: "GREECE" },
    { value: "GRLD", label: "GREENLAND" },
    { value: "GREN", label: "GRENADA" },
    { value: "GUAM", label: "GUAM" },
    { value: "GUAT", label: "GUATEMALA" },
    { value: "GNEA", label: "GUINEA" },
    { value: "GUIB", label: "GUINEA - BISSAU" },
    { value: "GUY", label: "GUYANA" },
    { value: "HAT", label: "HAITI" },
    { value: "HMD", label: "HEARD AND MCDONALD ISLANDS" },
    { value: "VAT", label: "HOLY SEE (VATICAN CITY)" },
    { value: "HOND", label: "HONDURAS" },
    { value: "HOKO", label: "HONG KONG BNO" },
    { value: "HNK", label: "HONG KONG SAR" },
    { value: "XHI", label: "HOWLAND ISLAND" },
    { value: "HUNG", label: "HUNGARY" },
    { value: "ICLD", label: "ICELAND" },
    { value: "XIR", label: "IN THE AIR" },
    { value: "IND", label: "INDIA" },
    { value: "IDSA", label: "INDONESIA" },
    { value: "IRAN", label: "IRAN" },
    { value: "IRAQ", label: "IRAQ" },
    { value: "IRE", label: "IRELAND" },
    { value: "ISRL", label: "ISRAEL" },
    { value: "ITLY", label: "ITALY" },
    { value: "JAM", label: "JAMAICA" },
    { value: "JPN", label: "JAPAN" },
    { value: "JRSM", label: "JERUSALEM" },
    { value: "JORD", label: "JORDAN" },
    { value: "KAZ", label: "KAZAKHSTAN" },
    { value: "KENY", label: "KENYA" },
    { value: "KIRI", label: "KIRIBATI" },
    { value: "PRK", label: "KOREA, DEMOCRATIC REPUBLIC OF (NORTH)" },
    { value: "KOR", label: "KOREA, REPUBLIC OF (SOUTH)" },
    { value: "KSV", label: "KOSOVO" },
    { value: "KUWT", label: "KUWAIT" },
    { value: "KGZ", label: "KYRGYZSTAN" },
    { value: "LAOS", label: "LAOS" },
    { value: "LATV", label: "LATVIA" },
    { value: "LEBN", label: "LEBANON" },
    { value: "LES", label: "LESOTHO" },
    { value: "LIBR", label: "LIBERIA" },
    { value: "LBYA", label: "LIBYA" },
    { value: "LCHT", label: "LIECHTENSTEIN" },
    { value: "LITH", label: "LITHUANIA" },
    { value: "LXM", label: "LUXEMBOURG" },
    { value: "MAC", label: "MACAU" },
    { value: "MKD", label: "MACEDONIA, NORTH" },
    { value: "MADG", label: "MADAGASCAR" },
    { value: "MALW", label: "MALAWI" },
    { value: "MLAS", label: "MALAYSIA" },
    { value: "MLDI", label: "MALDEN ISLAND" },
    { value: "MLDV", label: "MALDIVES" },
    { value: "MALI", label: "MALI" },
    { value: "MLTA", label: "MALTA" },
    { value: "RMI", label: "MARSHALL ISLANDS" },
    { value: "MAUR", label: "MAURITANIA" },
    { value: "MRTS", label: "MAURITIUS" },
    { value: "MYT", label: "MAYOTTE" },
    { value: "AGS", label: "MEXICO - AGUASCALIENTES" },
    { value: "BC", label: "MEXICO - BAJA CALIFORNIA NORTE" },
    { value: "BCSR", label: "MEXICO - BAJA CALIFORNIA SUR" },
    { value: "CAMP", label: "MEXICO - CAMPECHE" },
    { value: "CHIS", label: "MEXICO - CHIAPAS" },
    { value: "CHIH", label: "MEXICO - CHIHUAHUA" },
    { value: "COAH", label: "MEXICO - COAHUILA" },
    { value: "COLI", label: "MEXICO - COLIMA" },
    { value: "DF", label: "MEXICO - DISTRITO FEDERAL" },
    { value: "DGO", label: "MEXICO - DURANGO" },
    { value: "GTO", label: "MEXICO - GUANAJUATO" },
    { value: "GRO", label: "MEXICO - GUERRERO" },
    { value: "HGO", label: "MEXICO - HIDALGO" },
    { value: "JAL", label: "MEXICO - JALISCO" },
    { value: "MCH", label: "MEXICO - MICHOACAN" },
    { value: "MOR", label: "MEXICO - MORELOS" },
    { value: "NAY", label: "MEXICO - NAYARIT" },
    { value: "NL", label: "MEXICO - NUEVO LEON" },
    { value: "OAX", label: "MEXICO - OAXACA" },
    { value: "PUE", label: "MEXICO - PUEBLA" },
    { value: "QRO", label: "MEXICO - QUERETARO" },
    { value: "QROO", label: "MEXICO - QUINTANA ROO" },
    { value: "SLP", label: "MEXICO - SAN LUIS POTOSI" },
    { value: "SIN", label: "MEXICO - SINALOA" },
    { value: "SON", label: "MEXICO - SONORA" },
    { value: "MXCO", label: "MEXICO - STATE OF MEXICO" },
    { value: "TAB", label: "MEXICO - TABASCO" },
    { value: "TAMP", label: "MEXICO - TAMAULIPAS" },
    { value: "TLAX", label: "MEXICO - TLAXCALA" },
    { value: "VER", label: "MEXICO - VERACRUZ" },
    { value: "YUC", label: "MEXICO - YUCATAN" },
    { value: "ZAC", label: "MEXICO - ZACATECAS" },
    { value: "FSM", label: "MICRONESIA" },
    { value: "MDWI", label: "MIDWAY ISLANDS" },
    { value: "MLD", label: "MOLDOVA" },
    { value: "MON", label: "MONACO" },
    { value: "MONG", label: "MONGOLIA" },
    { value: "MTG", label: "MONTENEGRO" },
    { value: "MONT", label: "MONTSERRAT" },
    { value: "MORO", label: "MOROCCO" },
    { value: "MOZ", label: "MOZAMBIQUE" },
    { value: "NAMB", label: "NAMIBIA" },
    { value: "NAU", label: "NAURU" },
    { value: "NEP", label: "NEPAL" },
    { value: "NETH", label: "NETHERLANDS" },
    { value: "NCAL", label: "NEW CALEDONIA" },
    { value: "NZLD", label: "NEW ZEALAND" },
    { value: "NIC", label: "NICARAGUA" },
    { value: "NIR", label: "NIGER" },
    { value: "NRA", label: "NIGERIA" },
    { value: "NIUE", label: "NIUE" },
    { value: "NFK", label: "NORFOLK ISLAND" },
    { value: "MNP", label: "NORTH MARIANA ISLANDS" },
    { value: "NIRE", label: "NORTHERN IRELAND" },
    { value: "NORW", label: "NORWAY" },
    { value: "OMAN", label: "OMAN" },
    { value: "PKST", label: "PAKISTAN" },
    { value: "PALA", label: "PALAU" },
    { value: "PLMR", label: "PALMYRA ATOLL" },
    { value: "PAN", label: "PANAMA" },
    { value: "PNG", label: "PAPUA NEW GUINEA" },
    { value: "PARA", label: "PARAGUAY" },
    { value: "PERU", label: "PERU" },
    { value: "PHIL", label: "PHILIPPINES" },
    { value: "PITC", label: "PITCAIRN ISLANDS" },
    { value: "POL", label: "POLAND" },
    { value: "PORT", label: "PORTUGAL" },
    { value: "PR", label: "PUERTO RICO" },
    { value: "QTAR", label: "QATAR" },
    { value: "ROM", label: "ROMANIA" },
    { value: "RUS", label: "RUSSIA" },
    { value: "RWND", label: "RWANDA" },
    { value: "MAF", label: "SAINT MARTIN" },
    { value: "WSAM", label: "SAMOA" },
    { value: "SMAR", label: "SAN MARINO" },
    { value: "STPR", label: "SAO TOME AND PRINCIPE" },
    { value: "SARB", label: "SAUDI ARABIA" },
    { value: "SENG", label: "SENEGAL" },
    { value: "SBA", label: "SERBIA" },
    { value: "SEYC", label: "SEYCHELLES" },
    { value: "SLEO", label: "SIERRA LEONE" },
    { value: "SING", label: "SINGAPORE" },
    { value: "STM", label: "SINT MAARTEN" },
    { value: "SVK", label: "SLOVAKIA" },
    { value: "SVN", label: "SLOVENIA" },
    { value: "SLMN", label: "SOLOMON ISLANDS" },
    { value: "SOMA", label: "SOMALIA" },
    { value: "SAFR", label: "SOUTH AFRICA" },
    { value: "SGS", label: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLAND" },
    { value: "SSDN", label: "SOUTH SUDAN" },
    { value: "SPN", label: "SPAIN" },
    { value: "SRL", label: "SRI LANKA" },
    { value: "STBR", label: "ST. BARTHELEMY" },
    { value: "SHEL", label: "ST. HELENA" },
    { value: "STCN", label: "ST. KITTS AND NEVIS" },
    { value: "SLCA", label: "ST. LUCIA" },
    { value: "SPMI", label: "ST. PIERRE AND MIQUELON" },
    { value: "STVN", label: "ST. VINCENT AND THE GRENADINES" },
    { value: "SUDA", label: "SUDAN" },
    { value: "SURM", label: "SURINAME" },
    { value: "SJM", label: "SVALBARD" },
    { value: "SWDN", label: "SWEDEN" },
    { value: "SWTZ", label: "SWITZERLAND" },
    { value: "SYR", label: "SYRIA" },
    { value: "TWAN", label: "TAIWAN" },
    { value: "TJK", label: "TAJIKISTAN" },
    { value: "TAZN", label: "TANZANIA" },
    { value: "THAI", label: "THAILAND" },
    { value: "TMOR", label: "TIMOR-LESTE" },
    { value: "TOGO", label: "TOGO" },
    { value: "TKL", label: "TOKELAU" },
    { value: "TONG", label: "TONGA" },
    { value: "TRIN", label: "TRINIDAD AND TOBAGO" },
    { value: "TNSA", label: "TUNISIA" },
    { value: "TRKY", label: "TURKEY" },
    { value: "TKM", label: "TURKMENISTAN" },
    { value: "TCIS", label: "TURKS AND CAICOS ISLANDS" },
    { value: "TUV", label: "TUVALU" },
    { value: "UGAN", label: "UGANDA" },
    { value: "UKR", label: "UKRAINE" },
    { value: "UAE", label: "UNITED ARAB EMIRATES" },
    { value: "GRBR", label: "UNITED KINGDOM" },
    { value: "USA", label: "UNITED STATES OF AMERICA" },
    { value: "URU", label: "URUGUAY" },
    { value: "UZB", label: "UZBEKISTAN" },
    { value: "VANU", label: "VANUATU" },
    { value: "VENZ", label: "VENEZUELA" },
    { value: "VTNM", label: "VIETNAM" },
    { value: "VI", label: "VIRGIN ISLANDS (U.S.)" },
    { value: "BRVI", label: "VIRGIN ISLANDS, BRITISH" },
    { value: "WKI", label: "WAKE ISLAND" },
    { value: "WAFT", label: "WALLIS AND FUTUNA ISLANDS" },
    { value: "XWB", label: "WEST BANK" },
    { value: "SSAH", label: "WESTERN SAHARA" },
    { value: "YEM", label: "YEMEN" },
    { value: "ZAMB", label: "ZAMBIA" },
    { value: "ZIMB", label: "ZIMBABWE" },
];
