import { IBooleanQuestion } from "../IBooleanQuestion";
import { IComboQuestion } from "../IComboQuestion";
import { IDateQuestion } from "../IDateQuestion";
import { IDropdownQuestion } from "../IDropdownQuestion";
import { IMultiComboQuestion } from "../IMultiComboQuestion";
import { IMultiTextListQuestion } from "../IMultiTextListQuestion";
import { IMultiTextQuestion } from "../IMultiTextQuestion";
import { ITextQuestion } from "../ITextQuestion";
import { personalP1 } from "./1-personalP1";
import { personalP2 } from "./2-personalP2";
import { visaPurposePage } from "./3-visaPurposePage";
import { travelCompanionsPage } from "./4-travelCompanionsPage";
import { previousUsTravelPage } from "./5-previousUsTravelPage";
import { addressAndPhonePage } from "./6-addressAndPhonePage";

export type FormQuestion =
    | ITextQuestion
    | IBooleanQuestion
    | IMultiTextListQuestion
    | IMultiTextQuestion
    | IDropdownQuestion
    | IDateQuestion
    | IMultiComboQuestion
    | IComboQuestion;

export const formManifest = {
    personalP1: personalP1,
    personalP2: personalP2,
    visaPurposePage: visaPurposePage,
    travelCompanionsPage: travelCompanionsPage,
    previousUsTravelPage: previousUsTravelPage,
    addressAndPhonePage: addressAndPhonePage,
};
