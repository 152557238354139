import * as React from "react";
import { AppRoute } from "../../../../services/Constants/AppRoute";
import { BreadCrumb, IPage } from "../../../atoms/BreadCrumb/BreadCrumb";
import { formManifest } from "./manifest/formManifest";
import { Question } from "./Question";

interface IProps {}

export const Form: React.FC<React.PropsWithChildren<IProps>> = ({}) => {
    const pages: Array<IPage> = [{ name: "Applications", href: AppRoute.Applications }, { name: "projectName" }];
    const questionListP1 = formManifest.personalP1.map((question) => <Question key={question.jsonPath} question={question} />);
    const questionListP2 = formManifest.personalP2.map((question) => <Question key={question.jsonPath} question={question} />);
    const questionListP3 = formManifest.visaPurposePage.map((question) => <Question key={question.jsonPath} question={question} />);
    const questionListP4 = formManifest.travelCompanionsPage.map((question) => <Question key={question.jsonPath} question={question} />);
    const questionListP5 = formManifest.previousUsTravelPage.map((question) => <Question key={question.jsonPath} question={question} />);
    const questionListP6 = formManifest.addressAndPhonePage.map((question) => <Question key={question.jsonPath} question={question} />);
    return (
        <>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <BreadCrumb pages={pages} />
            </div>

            {questionListP1}
            {questionListP2}
            {questionListP3}
            {questionListP4}

            {questionListP5}
            {questionListP6}
        </>
    );
};
