import { lengthOfTimeUnitLabels } from "@ds160/library";
import { FormQuestion } from "./formManifest";
import { getOptionsFromLabels } from "./getOptionsFromLabels";

export const previousUsTravelPage: ReadonlyArray<FormQuestion> = [
    {
        title: "Have you ever been in the U.S.?",
        type: "boolean",
        jsonPath: "previousTravel",
        subQuestions: {
            yes: {
                help: {
                    title: "Previous U.S. Visits",
                    content: "If you are unsure about when you visited the U.S., please provide a best estimate.",
                },
                type: "multi-combo",
                title: "Provide information on your last five U.S. visits:",
                jsonPath: "previousVisits",
                options: [
                    { type: "date", title: "Date Arrived", jsonPath: "arrivalDate" },
                    { title: "Intended length of stay in the U.S.", jsonPath: "lengthOfStayNumber", type: "text" },
                    {
                        title: "",
                        jsonPath: "lengthOfStayNumberUnits",
                        type: "dropdown",
                        options: getOptionsFromLabels(lengthOfTimeUnitLabels),
                    },
                ],
            },
        },
    },
    {
        title: "Have you ever been issued a U.S. Visa?",
        type: "boolean",
        jsonPath: "previousVisa",
        subQuestions: {
            yes: {
                title: "Previous U.S. Visa",
                type: "combo",
                jsonPath: "previousVisaDetails",
                options: [
                    { type: "date", title: "Date Last Visa Was Issued", jsonPath: "dateIssued" },
                    {
                        type: "text",
                        title: "Visa Number",
                        jsonPath: "visaNumber",
                        na: { title: "Do not know" },
                        help: {
                            title: "Visa Number",
                            content:
                                "Enter the 8-digit number that is displayed in red on the lower right hand side of your visa. If your previous visa was a Border Crossing Card enter the last 12-digit number of the first line of the machine readable zone.",
                        },
                    },
                    { title: "Are you applying for the same type of visa?", jsonPath: "sameVisaType", type: "boolean" },
                    {
                        title: "Are you applying in the same country or location where the visa above was issued, and is this country or location your place of principal of residence? ",
                        jsonPath: "sameCountry",
                        type: "boolean",
                    },
                    {
                        title: "Have you been ten-printed?",
                        jsonPath: "tenPrinted",
                        type: "boolean",
                        help: {
                            title: "Ten-printed",
                            content:
                                "Ten-printed means that you have provided fingerprints for all your fingers, as opposed to having provided only two fingerprints.",
                        },
                    },
                    {
                        title: "Has your U.S. Visa ever been lost or stolen?",
                        jsonPath: "lostOrStolen",
                        type: "boolean",
                        subQuestions: {
                            yes: {
                                title: "",
                                type: "combo",
                                jsonPath: "lostOrStolenDetails",
                                options: [
                                    {
                                        type: "text",
                                        title: "Enter year visa was lost or stolen:",
                                        jsonPath: "lostOrStolenYear",
                                    },
                                    { title: "Explain", jsonPath: "lostOrStolenExplanation", type: "text" },
                                ],
                            },
                        },
                    },
                    {
                        title: "Has your U.S. Visa ever been cancelled or revoked?",
                        jsonPath: "cancelledOrRevoked",
                        type: "boolean",
                        subQuestions: {
                            yes: {
                                title: "Explain why your visa was cancelled or revoked",
                                type: "text",
                                jsonPath: "cancelledOrRevokedDetails",
                            },
                        },
                    },
                ],
            },
        },
    },
    {
        title: "Have you ever been refused a U.S. Visa, or been refused admission to the United States, or withdrawn your application for admission at the port of entry?",
        type: "boolean",
        jsonPath: "previousRefusal",
        subQuestions: {
            yes: {
                title: "Explain why you were refused a visa or admission to the United States, or why you withdrew your application for admission at the port of entry",
                type: "text",
                jsonPath: "previousRefusalDetails",
            },
        },
    },
    {
        title: "Has anyone ever filed an immigrant petition on your behalf with the United States Citizenship and Immigration Services?",
        type: "boolean",
        jsonPath: "previousPetition",
        subQuestions: {
            yes: {
                title: "Explain the circumstances of the petition",
                type: "text",
                jsonPath: "previousPetitionDetails",
            },
        },
    },
];
