import * as React from "react";
import { useState } from "react";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { IDateQuestion } from "../IDateQuestion";

interface IProps {
    readonly question: IDateQuestion;
}

export const DateQuestion: React.FC<React.PropsWithChildren<IProps>> = ({ question }) => {
    const { title, help } = question;

    const [value, setValue] = useState<DateValueType>({
        startDate: null,
        endDate: null,
    });

    const onChange = (newValue: DateValueType) => {
        setValue(newValue); // Update the state with the new value
        console.log("Selected date:", newValue);
    };

    return (
        <div className="bg-white px-4 py-5 sm:px-6">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                    <h2 className="text-base font-semibold text-gray-900">{help?.title}</h2>
                    <p className="mt-1 text-sm text-gray-600">{help?.content}</p>
                </div>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    <div className="sm:col-span-4">
                        <label htmlFor="datepicker" className="block text-sm font-medium text-gray-900">
                            {title}
                        </label>
                        <div className="mt-2">
                            <Datepicker value={value} onChange={onChange} asSingle={true} useRange={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
