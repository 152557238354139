import * as React from "react";
import { useCallback, useState } from "react";
import { ITextQuestion } from "../ITextQuestion";

interface IProps {
    readonly question: ITextQuestion;
}

export const TextQuestion: React.FC<React.PropsWithChildren<IProps>> = ({ question }) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const { jsonPath, title, type, hint, help, na } = question;
    const notApplicableText = na?.title;

    const onNaToggled = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(event.target.checked);
    }, []);

    const naField = notApplicableText ? (
        <div className="mt-2 relative flex items-start">
            <div className="flex h-6 items-center">
                <input
                    id="offers"
                    name="offers"
                    type="checkbox"
                    onChange={onNaToggled}
                    aria-describedby="offers-description"
                    className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
            <div className="ml-3 text-sm/6">
                <label htmlFor="offers" className="font-medium text-gray-900">
                    NA
                </label>{" "}
                <span id="offers-description" className="text-gray-500">
                    <span className="sr-only">NA </span>
                    {notApplicableText}
                </span>
            </div>
        </div>
    ) : null;

    return (
        <>
            <div className="bg-white px-4 py-5 sm:px-6">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base/7 font-semibold text-gray-900">{help?.title}</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">{help?.content}</p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-4">
                            <div>
                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                    {title}
                                </label>
                                <div className={disabled ? "hidden" : "mt-2"}>
                                    <input
                                        disabled={disabled}
                                        id={jsonPath}
                                        name={jsonPath}
                                        type={type}
                                        placeholder={hint}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                </div>
                                {naField}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
