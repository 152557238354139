import { originCountryList } from "@ds160/library";
import { FormQuestion } from "./formManifest";

export const personalP2: ReadonlyArray<FormQuestion> = [
    {
        jsonPath: "countryOfOrigin",
        type: "dropdown",
        title: "Country/Region of Origin (Nationality) ",
        options: originCountryList,
    },
    {
        jsonPath: "previousNationalities",
        type: "boolean",
        title: "Do you hold or have you held any nationality other than the one indicated above on nationality?",
        subQuestions: {
            yes: {
                title: "Other Nationalities",
                type: "multi-combo",
                jsonPath: "previousNationalities",
                options: [
                    {
                        type: "dropdown",
                        jsonPath: "otherNationality",
                        title: "Other Country/Region of Origin (Nationality)",
                        help: {
                            title: "Other Nationalities",
                            content:
                                "Enter all nationalities you currently hold and all nationalities you have previously held, regardless of whether you have formally and/or legally relinquished the nationality. If the country where you previously held nationality is no longer a political entity or country, select the nationality that corresponds with the name of the country that is currently used for that location.",
                        },
                        options: originCountryList,
                    },
                    {
                        type: "boolean",
                        jsonPath: "otherNationality",
                        title: "Do you hold a passport for the other country/region of origin (nationality) above?",
                        subQuestions: {
                            yes: {
                                type: "text",
                                jsonPath: "otherNationalityPassportNumber",
                                title: "Passport Number",
                                help: {
                                    title: "Passport Number",
                                    content: "Enter the passport number exactly as it appears on the passport.",
                                },
                            },
                        },
                    },
                ],
            },
        },
    },
    {
        title: "Are you a permanent resident of a country/region other than your country/region of origin (nationality) indicated above?",
        type: "boolean",
        jsonPath: "permanentResident",
        help: {
            title: "Permanent Resident",
            content:
                "Permanent resident means any individual who has been legally granted by a country/region permission to live and work without time limitation in that country/region.",
        },
        subQuestions: {
            yes: {
                type: "multi-combo",
                jsonPath: "permanentResident",
                title: "Other Permanent Resident Country/Region ",
                options: [
                    {
                        type: "dropdown",
                        jsonPath: "permanentResidentCountry",
                        title: "Country/Region of Permanent Residence",
                        options: originCountryList,
                    },
                ],
            },
        },
    },
    {
        type: "combo",
        title: "",
        jsonPath: "nationalId",
        help: {
            title: "Identification Numbers",
            content:
                "Your National ID Number is a unique number that your government provides. The U.S. Government provides unique numbers to those who seek employment (Social Security Number) or pay taxes (Taxpayer ID).",
        },
        options: [
            {
                title: "National Identification Number",
                type: "text",
                jsonPath: "nationalIdNumber",
            },
            {
                title: "U.S. Social Security Number",
                type: "text",
                jsonPath: "nationalIdNumber",
                na: {
                    title: "Does Not Apply",
                },
            },
            {
                title: "U.S. Taxpayer ID Number",
                type: "text",
                jsonPath: "nationalIdNumber",
                na: {
                    title: "Does Not Apply",
                },
            },
        ],
    },
];
