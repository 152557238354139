import { countryRegionLabels, socialMediaPlatformLabels } from "@ds160/library";
import { FormQuestion } from "./formManifest";
import { getOptionsFromLabels } from "./getOptionsFromLabels";

const countryOptions = getOptionsFromLabels(countryRegionLabels);
const socialMediaOptions = getOptionsFromLabels(socialMediaPlatformLabels);

export const addressAndPhonePage: ReadonlyArray<FormQuestion> = [
    {
        type: "combo",
        title: "Home Address",
        jsonPath: "homeAddress",
        options: [
            { type: "text", title: "Street Address (Line 1)", jsonPath: "streetAddress1" },
            { type: "text", title: "Street Address (Line 2)", jsonPath: "streetAddress2" },
            { type: "text", title: "City", jsonPath: "city" },
            { type: "text", title: "State/Province", jsonPath: "state", na: { title: "Does not apply" } },
            { type: "text", title: "Postal Zone/ZIP Code ", jsonPath: "postalCode", na: { title: "Does not apply" } },
            { type: "dropdown", title: "Country/Region", jsonPath: "country", options: countryOptions },
        ],
    },

    {
        help: {
            title: "Mailing Address",
            content: "If your mailing address is different from your home address, please provide your mailing address.",
        },
        type: "boolean",
        title: "Is your Mailing Address the same as your Home Address?",
        jsonPath: "mailingAddress",
        subQuestions: {
            yes: {
                type: "combo",
                title: "Mailing Address",
                jsonPath: "mailingAddress",
                options: [
                    { type: "text", title: "Street Address (Line 1)", jsonPath: "streetAddress1" },
                    { type: "text", title: "Street Address (Line 2)", jsonPath: "streetAddress2" },
                    { type: "text", title: "City", jsonPath: "city" },
                    { type: "text", title: "State/Province", jsonPath: "state", na: { title: "Does not apply" } },
                    { type: "text", title: "Postal Zone/ZIP Code ", jsonPath: "postalCode", na: { title: "Does not apply" } },
                    { type: "dropdown", title: "Country/Region", jsonPath: "country", options: countryOptions },
                ],
            },
        },
    },
    {
        help: {
            title: "Phone",
            content:
                "You must provide a primary phone number. The primary phone number should be the phone number at which you are most likely to be reached; this could be a land line or a cellular/mobile number. If you have an additional land line or a cellular/mobile number please list that as your secondary phone number.",
        },
        title: "Phone",
        type: "combo",
        jsonPath: "phone",
        options: [
            {
                title: "Primary Phone Number",
                jsonPath: "primaryPhoneNumber",
                type: "text",
            },
            {
                title: "Secondary Phone Number",
                jsonPath: "secondaryPhoneNumber",
                type: "text",
                na: { title: "Does not apply" },
            },
            {
                title: "Work Phone Number",
                jsonPath: "workPhoneNumber",
                type: "text",
                na: { title: "Does not apply" },
            },
        ],
    },
    {
        title: "Have you used any other phone numbers in the last five years?",
        type: "boolean",
        jsonPath: "otherPhoneNumbers",
        subQuestions: {
            yes: {
                type: "multi-combo",
                title: "Provide the following information for each phone number:",
                jsonPath: "otherPhoneNumbers",
                options: [{ type: "text", title: "Additional Phone Number", jsonPath: "phoneNumber" }],
            },
        },
    },
    {
        help: {
            title: "Email Address",
            content:
                "You must provide an email address.  The email address you provide will be used for correspondence purposes.  Provide an email address that is secure and to which you have reasonable access.",
        },
        title: "Email Address",
        type: "text",
        jsonPath: "emailAddress",
    },
    {
        type: "boolean",
        title: "Have you used any other email addresses in the last five years?",
        jsonPath: "otherEmailAddresses",
        subQuestions: {
            yes: {
                type: "multi-combo",
                title: "",
                jsonPath: "otherEmailAddresses",
                options: [{ type: "text", title: "Additional Email Address", jsonPath: "emailAddress" }],
            },
        },
    },
    {
        help: {
            content:
                "Enter information associated with your online presence, including the types of online providers/platforms, applications and websites that you use to collaborate, share information, and interact with others. List the username, handle, screen-name, or other identifiers associated with your social media profile. (You do not need to list accounts designed for use by multiple users within a business or other organization.)",
            title: "Social Media",
        },
        title: "Social Media",
        type: "multi-combo",
        jsonPath: "socialMedia",
        options: [
            { type: "dropdown", title: "Social Media Provider/Platform", jsonPath: "provider", options: socialMediaOptions },
            { type: "text", title: "Social Media Identifier", jsonPath: "username" },
        ],
    },
    {
        title: "Do you wish to provide information about your presence on any other websites or applications you have used within the last five years to create or share content (photos, videos, status updates, etc.)?",
        type: "boolean",
        jsonPath: "otherOnlinePresence",
        subQuestions: {
            yes: {
                type: "multi-combo",
                title: "Provide the following information for each website or application:",
                jsonPath: "otherOnlinePresence",
                options: [
                    { type: "text", title: "Additional Social Media Platform", jsonPath: "provider" },
                    { type: "text", title: "Additional Social Media Handle", jsonPath: "username" },
                ],
            },
        },
    },
];
