import { birthCountryList } from "./birthCountryList";
import { FormQuestion } from "./formManifest";

export const personalP1: ReadonlyArray<FormQuestion> = [
    {
        jsonPath: "surnames",
        title: "Surname",
        type: "text",
        hint: "(e.g., FERNANDEZ GARCIA)",
        help: {
            title: "Surnames",
            content: "Enter all surnames as listed in your passport. If only one name is listed in your passport, enter that Surname.",
        },
    },
    {
        jsonPath: "givenNames",
        title: "Given Names",
        hint: "(e.g., JUAN MIGUEL)",
        type: "text",
        help: {
            title: "Given Names",
            content: "If your passport does not include a given name, please enter 'FNU' in Given Names.",
        },
    },

    {
        jsonPath: "fullNameNativeAlphabet",
        title: "Full Name in Native Alphabet",
        type: "text",
        na: {
            title: "Does Not Apply/Technology Not Available",
        },
        help: {
            title: "Given Names",
            content: "If your passport does not include a given name, please enter 'FNU' in Given Names.",
        },
    },
    {
        jsonPath: "hasOtherNames",
        type: "boolean",
        title: "Have you ever used other names (i.e., maiden, religious, professional, alias, etc.)?",
        help: {
            title: "Other Names",
            content:
                "Other names used include your maiden name, religious name, professional name, or any other names which you are known by or have been known by in the past.",
        },
        subQuestions: {
            yes: {
                help: {
                    title: "Other Names",
                    content:
                        "If you only have other surnames to enter, enter the same given names as above. Conversely, if you only have other given names to enter, enter the same surname as above.",
                },
                type: "multi-text-list",
                jsonPath: "otherSurnamesNames",
                title: "Provide the following information:",
                options: [
                    {
                        jsonPath: "otherSurname",
                        title: "Other Surnames Used (maiden, religious, professional, aliases, etc.) ",
                    },
                    {
                        jsonPath: "otherGivenNames",
                        title: "Other Given Names Used",
                    },
                ],
            },
        },
    },
    {
        jsonPath: "telecodeName",
        type: "boolean",
        title: "Do you have a telecode that represents your name?",
        help: {
            title: "Telecode",
            content: "Telecodes are 4 digit code numbers that represent characters in some non-Roman alphabet names.",
        },
        subQuestions: {
            yes: {
                help: {
                    title: "Other Names",
                    content:
                        "If you only have other surnames to enter, enter the same given names as above. Conversely, if you only have other given names to enter, enter the same surname as above.",
                },
                type: "multi-text",
                jsonPath: "otherSurnamesNames",
                title: "Provide the following information:",
                options: [
                    {
                        jsonPath: "telecodeSurnames",
                        title: "Telecode Surnames",
                    },
                    {
                        jsonPath: "telecodeGivenNames",
                        title: "Telecode Given Names",
                    },
                ],
            },
        },
    },
    {
        jsonPath: "sex",
        type: "dropdown",
        title: "Sex",
        help: {
            title: "Sex",
            content: "Please select MALE or FEMALE",
        },
        options: [
            {
                value: "M",
                label: "MALE",
            },
            {
                value: "F",
                label: "FEMALE",
            },
        ],
    },

    {
        jsonPath: "maritalStatus",
        type: "dropdown",
        title: "Marital Status",
        options: [
            {
                value: "M",
                label: "MARRIED",
            },
            {
                value: "C",
                label: "COMMON LAW MARRIAGE",
            },

            {
                value: "P",
                label: "CIVIL UNION / DOMESTIC PARTNERSHIP",
            },
            {
                value: "S",
                label: "SINGLE",
            },
            {
                value: "W",
                label: "WIDOWED",
            },
            {
                value: "D",
                label: "DIVORCED",
            },
            {
                value: "L",
                label: "LEGALLY SEPARATED",
            },
            {
                value: "O",
                label: "OTHER",
            },
        ],
    },

    {
        jsonPath: "dateOfBirth",
        type: "date",
        title: "Date of Birth",
        help: {
            title: "Date of Birth",
            content: "If day or month is unknown, enter as shown in passport.",
        },
    },

    {
        jsonPath: "cityOfBirth",
        type: "text",
        title: "City of Birth",
    },

    {
        jsonPath: "stateOfBirth",
        type: "text",
        title: "State/Province of Birth",
        na: {
            title: "Does Not Apply",
        },
    },
    {
        jsonPath: "countryOfBirth",
        type: "dropdown",
        title: "Country/Region of Birth",
        help: {
            title: "Country/Region",
            content: "Select the name that is currently in use for the place where you were born.",
        },
        options: birthCountryList,
    },
];
